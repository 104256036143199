import { getAuth } from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import useFirebase from "./useFirebase";
import { useCallback } from "react";
import { Account } from "types";

export const useAccountSelectPersist = () => {
  const { app } = useFirebase();
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const [user] = useAuthState(auth);

  const setSelectedAcount = useCallback(
    async (account: Account) => {
      const selectedDoc = doc(
        firestore,
        `/analyzer/${user?.uid}/selectedAccounts/selectedAccount`
      );
      try {
        await setDoc(selectedDoc, account);
        return account;
      } catch (err) {
        return account;
      }
    },
    [firestore, user?.uid]
  );

  return { setSelectedAcount };
};
