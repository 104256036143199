import React, { ReactNode } from "react";
import navigation from "config/navigation.config";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  HiOutlineCash,
  HiOutlineChartBar,
  HiOutlineClipboardCheck,
  HiOutlineFolder,
  HiOutlineDocumentAdd,
} from "react-icons/hi";

const iconMap: { [key: string]: ReactNode } = {
  income: <HiOutlineCash />,
  strategy: <HiOutlineChartBar />,
  transactions: <HiOutlineClipboardCheck />,
  positions: <HiOutlineFolder />,
  document: <HiOutlineDocumentAdd />,
};

const SideNavigationItem: React.FC<{
  title: string;
  highlighted: boolean;
  path: string;
  icon?: string;
}> = ({ title, highlighted, path, icon }) => {
  const baseStyle = "py-3 px-2 cursor-pointer group rounded";
  return (
    <Link
      to={path}
      className={classNames(
        baseStyle,
        highlighted ? "bg-primary bg-opacity-40 text-primary" : "text-gray-600"
      )}
    >
      <div className="flex items-center gap-3">
        <span
          className={classNames(
            "text-xl",
            "font-extrabold",
            "group-hover:text-primary",
            highlighted ? "text-primary" : "text-gray-600"
          )}
        >
          {icon && iconMap[icon]}
        </span>
        <span className="group-hover:text-primary text-sm font-bold">
          {title}
        </span>
      </div>
    </Link>
  );
};

const SideNavigation: React.FC = () => {
  const location = useLocation();
  return (
    <div className="hidden lg:flex h-screen text-black fixed z-[5]">
      <div className="flex flex-col w-64 bg-white border-r">
        <div className="flex items-center justify-center h-16 p-10">
          <img className="h-12" alt="Sivworks Logo" src="/full-logo.png" />
        </div>
        <nav className="flex flex-col flex-grow p-4 gap-1">
          {navigation.map((nav) => (
            <SideNavigationItem
              key={nav.key}
              title={nav.title}
              highlighted={nav.active.test(location.pathname)}
              path={nav.path}
              icon={nav.icon}
            />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SideNavigation;
