import { ReactNode, createContext, useContext, useState } from "react";
import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";

const createFirebaseApp = () => {
  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  if (!getApps.length) {
    initializeApp(config);
  }

  return getApp();
};

const context = createContext<{ app: FirebaseApp }>({
  app: createFirebaseApp(),
});

export const Firebase = ({ children }: { children: ReactNode }) => {
  return (
    <context.Consumer>
      {(value) => <context.Provider value={value}>{children}</context.Provider>}
    </context.Consumer>
  );
};

const useFirebase = () => {
  const [app] = useState(useContext(context));
  return app;
};

export default useFirebase;
