import { AccountSelect } from "components/AccountSelect";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { UserDropdown } from "components/UserDropdown";
import useUser from "hooks/useUser";

const TopNavigation: React.FC<{ id: string }> = ({ id }) => {
  const [scrolled, setScrolled] = useState(false);
  const { user, loading } = useUser();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={classNames(
        "sticky",
        "px-6",
        "py-3",
        "top-0",
        "bg-slate-50",
        "flex",
        "items-center",
        "justify-end",
        "w-full",
        "gap-4",
        "z-[3]",
        "transition-all",
        scrolled && "border-b-1 shadow !bg-white"
      )}
    >
      <div className="w-12 md:w-20 lg:hidden">
        <img src="/logo192.png" alt="Mobile Logo" className="h-10 md:h-14" />
      </div>
      <div className="flex gap-4 flex-grow justify-end z-0">
        <AccountSelect />
        <UserDropdown
          firstName={user?.firstName}
          lastName={user?.lastName}
          description={user?.email}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TopNavigation;
