import React from "react";
import { HiClipboardDocumentCheck, HiUserGroup } from "react-icons/hi2";

const ModalCircle: React.FC<{ icon?: "clipboard" | "user" }> = ({
  icon = "clipboard",
}) => {
  return (
    <div className="p-4">
      <div className="w-20 h-20 rounded-full bg-primary flex items-center justify-center shadow-gray-500 shadow-md overflow-visible">
        <span className="text-4xl text-white">
          {icon === "clipboard" ? (
            <HiClipboardDocumentCheck />
          ) : (
            <HiUserGroup />
          )}
        </span>
      </div>
    </div>
  );
};

export default ModalCircle;
