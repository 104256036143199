import { doc, getFirestore } from "firebase/firestore";
import useFirebase from "./useFirebase";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserConverter } from "utils/converters";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

const useUser = () => {
  const { app } = useFirebase();
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const [authUser] = useAuthState(auth);

  const userRef = doc(firestore, `/users/${authUser?.uid}`).withConverter(
    UserConverter
  );
  const [user, loading] = useDocumentDataOnce(userRef);
  return { user, loading };
};

export default useUser;
