import { useAccountSelectPersist } from "hooks/useAccountSelectPersist";
import { useAccountInfo } from "hooks/useAccounts";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  Listbox,
  ListboxItem,
  ScrollShadow,
  Selection,
} from "@nextui-org/react";

import { ColorCard } from "components/ColorCard";
import { AccountType } from "types";
import classNames from "classnames";
import { Key } from "react";
import { useNavigate } from "react-router-dom";

const AccountSelect = () => {
  const { accountInfo, setAccountInfo } = useAccountInfo();
  const { setSelectedAcount } = useAccountSelectPersist();
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const selectedAccount = accountInfo.selectedAccount!;
  const accounts = accountInfo.accounts!;
  const onSelectChange = (keys: Selection) => {
    if ((keys as Set<Key>).size > 0) {
      const key = Array.from(keys as Set<Key>)[0];
      const account = accounts.find((account) => account.id === key)!;
      navigate("/income");
      setSelectedAcount(account);
      setAccountInfo({ accounts, selectedAccount: account });
      onClose();
    }
  };

  const AccountType = ({ type }: { type: AccountType }) => {
    return (
      <ColorCard color={type === "live" ? "danger" : "success"}>
        <span
          className={classNames(
            "w-5",
            "h-5",
            "text-center",
            type === "live" ? "text-danger" : "text-success"
          )}
        >
          {type === "live" ? "L" : "P"}
        </span>
      </ColorCard>
    );
  };

  return (
    <>
      <Modal
        aria-label="Account Modal"
        placement="auto"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          <ModalHeader>Accounts</ModalHeader>
          <ModalBody>
            <ScrollShadow orientation="vertical" hideScrollBar>
              <Listbox
                aria-label="Account List"
                classNames={{
                  base: "max-w-lg",
                  list: "max-h-[300px]",
                }}
                itemClasses={{
                  base: "p-4",
                }}
                selectionMode="single"
                selectedKeys={[selectedAccount.id!]}
                onSelectionChange={onSelectChange}
              >
                {accounts.map((account) => {
                  return (
                    <ListboxItem
                      startContent={<AccountType type={account.type} />}
                      key={account.id!}
                      onPress={() => setSelectedAcount(account)}
                      description={
                        account.type === "live" ? account.brokerage : "practice"
                      }
                    >
                      {account.name}
                    </ListboxItem>
                  );
                })}
              </Listbox>
            </ScrollShadow>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        className="p-7"
        variant="ghost"
        size="lg"
        startContent={<AccountType type={selectedAccount.type} />}
        onClick={onOpen}
      >
        {selectedAccount.name}
      </Button>
    </>
  );
};

export default AccountSelect;
