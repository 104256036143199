import { SideNavigation } from "components/SideNavigation";
import { TopNavigation } from "components/TopNavigation";
import React from "react";
import { Views } from "./Views";
import { MobileNavigation } from "components/MobileNavigation";

const Layout: React.FC = () => {
  const id = "mobile-drawer";
  return (
    <div className="flex h-screen w-screen pb-16 lg:w-full lg:pb-0">
      <SideNavigation />
      <MobileNavigation />
      <div className="flex flex-col w-full lg:ml-64">
        <TopNavigation id={id} />
        <Views />
      </div>
    </div>
  );
};

export default Layout;
