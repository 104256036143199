import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  User,
  Skeleton,
} from "@nextui-org/react";
import useFirebase from "hooks/useFirebase";
import { BiSolidUserCircle, BiLogOut } from "react-icons/bi";
import { signOut, getAuth } from "firebase/auth";

type UserDropdownProps = {
  firstName?: string;
  lastName?: string;
  description?: string;
  loading?: boolean;
};

const UserSkeleton = () => {
  return (
    <div className="w-48 flex items-center gap-3">
      <div>
        <Skeleton className="flex rounded-full w-12 h-12" />
      </div>
      <div className="hidden w-full lg:flex flex-col gap-2">
        <Skeleton className="h-3 w-3/5 rounded-lg" />
        <Skeleton className="h-3 w-4/5 rounded-lg" />
      </div>
    </div>
  );
};

const UserWithDropdown = ({
  firstName,
  lastName,
  description,
}: UserDropdownProps) => {
  const { app } = useFirebase();
  const name = `${firstName?.charAt(0).toUpperCase()}${lastName
    ?.charAt(0)
    .toUpperCase()}`;
  return (
    <div className="hover:cursor-pointer pt-2">
      <Dropdown>
        <DropdownTrigger>
          <div>
            <div className="hidden md:block">
              <User
                name={`${firstName} ${lastName}`}
                description={description}
                isFocusable
                avatarProps={{
                  name,
                  isBordered: true,
                }}
              />
            </div>
            <div className="md:hidden flex items-center justify-center">
              <Avatar isBordered name={name} />
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownItem
            startContent={
              <BiSolidUserCircle className="text-gray-500 text-xl" />
            }
          >
            Profile
          </DropdownItem>
          <DropdownItem
            startContent={<BiLogOut className="text-gray-500 text-xl" />}
            onClick={() => signOut(getAuth(app))}
          >
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const UserDropdown = (props: UserDropdownProps) => {
  return (
    <>{!props.loading ? <UserWithDropdown {...props} /> : <UserSkeleton />}</>
  );
};

export default UserDropdown;
