import classNames from "classnames";
import { ReactNode } from "react";

type Color = "success" | "danger" | "warning";

const bgColors: { [key in Color]: string } = {
  success: "bg-success",
  warning: "bg-warning",
  danger: "bg-danger",
};

const ColorCard = ({
  color = "success",
  children,
}: {
  color?: Color;
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "p-2",
        "rounded-lg",
        "flex",
        "items-center",
        "justify-center",
        bgColors[color],
        "bg-opacity-40"
      )}
    >
      {children}
    </div>
  );
};

export default ColorCard;
