import { ReactNode } from "react";
import navigation from "config/navigation.config";
import {
  HiDocumentAdd,
  HiFolder,
  HiClipboardCheck,
  HiChartBar,
  HiCash,
} from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const iconMap: { [key: string]: ReactNode } = {
  income: <HiCash className="w-5 h-5" />,
  strategy: <HiChartBar className="w-5 h-5" />,
  transactions: <HiClipboardCheck className="w-5 h-5" />,
  positions: <HiFolder className="w-5 h-5" />,
  document: <HiDocumentAdd className="w-5 h-5" />,
};

const MobileNavigation = () => {
  const location = useLocation();
  return (
    <div className="lg:hidden btm-nav border-t-1 shadow-md z-10 text-gray-500">
      {navigation.map((nav, index) => {
        return (
          <Link
            to={nav.path}
            key={index}
            className={classNames(
              "text-xs",
              location.pathname === nav.path && "text-primary"
            )}
          >
            {iconMap[nav.icon]}
            <span
              className={classNames(
                "btm-nav-label",
                location.pathname === nav.path && "text-primary"
              )}
            >
              {nav.title}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default MobileNavigation;
