import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Spinner } from "@nextui-org/react";

type PageLoadProps = {
  children: ReactNode;
};

type PageLoading = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
};

const context = createContext<PageLoading>({
  isLoading: true,
  setIsLoading: () => {},
  text: "Loading",
  setText: () => {},
});

export const PageLoad = ({ children }: PageLoadProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState("Loading");
  return (
    <context.Consumer>
      {() => {
        return (
          <context.Provider value={{ isLoading, setIsLoading, text, setText }}>
            {isLoading && (
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner label={text} size="lg" />
              </div>
            )}
            {children}
          </context.Provider>
        );
      }}
    </context.Consumer>
  );
};

const usePageLoading = () => {
  return useContext(context);
};

export default usePageLoading;
