import classNames from "classnames";
import { ModalCircle } from "components/ModalCircle";
import { Formik, Form, Field } from "formik";
import { useAccountCreate } from "hooks/useAccountCreate";
import { Brokerage } from "types";
import React from "react";

const AccountCreate: React.FC<{
  open: boolean;
  close?: boolean;
  onClose?: () => void;
}> = ({ open, close = false, onClose = () => ({}) }) => {
  const { createAccount } = useAccountCreate();
  return (
    <dialog
      className={classNames(
        "modal",
        open && "modal-open",
        "modal-bottom",
        "sm:modal-middle"
      )}
    >
      <div className="modal-box flex flex-col items-center gap-2">
        {close && (
          <button
            onClick={onClose}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
        )}
        <ModalCircle />
        <span className="font-bold text-2xl">Create Account</span>
        <Formik
          initialValues={{ name: "", brokerage: "Fidelity" }}
          onSubmit={async (values, { setErrors }) => {
            const { name, brokerage: rawBrokerage } = values;
            const brokerage = rawBrokerage as Brokerage;
            if (!name) {
              setErrors({
                name: "Account name is required",
              });
              return;
            }
            try {
              await createAccount({ name, brokerage, type: "practice" });
              onClose();
            } catch (e) {
              console.error(e);
              setErrors({
                name: "Something went wrong, please try again later",
              });
            }
          }}
        >
          {({ errors }) => (
            <Form className="w-full flex flex-col items-center mt-3 gap-4">
              <Field
                type="text"
                className={classNames(
                  "input input-primary w-80",
                  errors.name && "border-red-600"
                )}
                placeholder="Ex. 401(K) Retirement"
                name="name"
              />
              <div className="flex flex-col">
                <Field
                  as="select"
                  placeholder=""
                  className={classNames(
                    "select select-primary w-80",
                    errors.name && "border-red-600"
                  )}
                  name="brokerage"
                >
                  <option>Fidelity</option>
                  <option>TD Ameritrade</option>
                  <option>Tastyworks</option>
                  <option>E*Trade</option>
                  <option>Robinhood</option>
                </Field>
                {errors.name && (
                  <span className="text-red-600">{errors.name}</span>
                )}
              </div>
              <div className="modal-action">
                <button
                  type="submit"
                  className="btn btn-primary text-white w-40"
                >
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </dialog>
  );
};

export default AccountCreate;
