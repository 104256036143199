import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import useFirebase from "./useFirebase";
import { useCallback } from "react";
import { Account } from "types";
import { useAccountInfo } from "./useAccounts";
import { AccountConverter } from "utils/converters";
import { useAccountSelectPersist } from "./useAccountSelectPersist";

export const useAccountCreate = () => {
  const { app } = useFirebase();
  const auth = getAuth(app);
  const firestore = getFirestore(app);

  const [user] = useAuthState(auth);
  const { setSelectedAcount } = useAccountSelectPersist();

  const { accountInfo = {}, setAccountInfo = () => ({}) } = useAccountInfo();
  const { accounts = [] } = accountInfo;

  const createAccount = useCallback(
    async (account: Account) => {
      const accountsRef = collection(
        firestore,
        `analyzer/${user?.uid}/accounts`
      ).withConverter(AccountConverter);

      const docRef = await addDoc(accountsRef, account);
      const newAccount: Account = { ...account, id: docRef.id };
      setAccountInfo({
        ...accountInfo,
        accounts: [...accounts, newAccount],
        selectedAccount: await setSelectedAcount(newAccount),
      });
    },
    [
      firestore,
      user?.uid,
      setAccountInfo,
      accountInfo,
      accounts,
      setSelectedAcount,
    ]
  );

  return { createAccount };
};
