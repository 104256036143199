import React, { ReactNode } from "react";
import { getAuth } from "firebase/auth";
import useFirebase from "hooks/useFirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Login } from "components/Login";

const AuthWall = ({ children }: { children: ReactNode }) => {
  const { app } = useFirebase();
  const [user, loading] = useAuthState(getAuth(app));

  return (
    <>
      {user && !loading ? (
        children
      ) : (
        <Login open={user !== undefined && !loading} />
      )}
    </>
  );
};

export default AuthWall;
