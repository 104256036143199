import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "components/Layout";
import "index.css";
import { Firebase } from "hooks/useFirebase";
import { PageLoad } from "hooks/usePageLoading";
import { AuthWall } from "components/AuthWall";
import { Accounts } from "hooks/useAccounts";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Firebase>
        <AuthWall>
          <PageLoad>
            <Accounts>
              <Layout />
            </Accounts>
          </PageLoad>
        </AuthWall>
      </Firebase>
    </BrowserRouter>
  );
};

export default App;
