const navigation = [
  {
    key: "income",
    title: "Income",
    path: "/income",
    active: /income/,
    icon: "income",
  },
  {
    key: "strategy",
    title: "Strategy",
    path: "/strategy",
    active: /strategy/,
    icon: "strategy",
  },
  {
    key: "trade-input",
    title: "New Trade",
    path: "/trade-input",
    active: /trade-input*/,
    icon: "document",
  },
  {
    key: "transactions",
    title: "Transactions",
    path: "/transactions",
    active: /transactions*/,
    icon: "transactions",
  },
  {
    key: "positions",
    title: "Positions",
    path: "/positions",
    active: /positions*/,
    icon: "positions",
  },
];

export default navigation;
