import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Account, Transaction, Position, Strategy, Leg, User } from "types";

export const AccountConverter: FirestoreDataConverter<Account> = {
  toFirestore(account: Account): DocumentData {
    return { ...account };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Account {
    const { name, brokerage, type } = snapshot.data();
    return {
      name,
      brokerage,
      type,
      id: snapshot.id,
    };
  },
};

export const TransactionConverter: FirestoreDataConverter<Transaction> = {
  toFirestore(transaction: Transaction): DocumentData {
    return { ...transaction };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Transaction {
    const {
      createdTs,
      lastUpdatedTs,
      opId,
      action,
      strategy,
      symbol,
      transactionDate,
      legs,
      balance,
    } = snapshot.data();
    return {
      createdTs,
      lastUpdatedTs,
      opId,
      action,
      strategy,
      symbol,
      balance,
      transactionDate,
      legs,
      id: snapshot.id,
    };
  },
};

export const PositionConverter: FirestoreDataConverter<Position> = {
  toFirestore(position: Position): DocumentData {
    return { ...position };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Position {
    const {
      createdTs,
      action,
      symbol,
      isOpen,
      entryDate,
      closeDate,
      balance,
      credit,
      debit,
      commission,
      strategy,
      legs,
    } = snapshot.data();
    return {
      createdTs,
      symbol,
      action,
      isOpen,
      entryDate,
      closeDate,
      balance,
      credit,
      debit,
      strategy,
      commission,
      legs,
      id: snapshot.id,
    };
  },
};

export const LegConverter: FirestoreDataConverter<Leg> = {
  toFirestore(leg: Leg): DocumentData {
    return { ...leg };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Leg {
    const {
      positionId,
      transactionId,
      isOpen,
      instrumentType,
      action,
      intent,
      quantity,
      price,
      value,
      commission,
      optionDetails,
    } = snapshot.data();
    return {
      positionId,
      transactionId,
      isOpen,
      instrumentType,
      action,
      intent,
      quantity,
      price,
      value,
      commission,
      optionDetails,
      id: snapshot.id,
    };
  },
};

export const StrategyConverter: FirestoreDataConverter<Strategy> = {
  toFirestore(strategy: Strategy): DocumentData {
    return { ...strategy };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Strategy {
    const {
      symbol,
      strategy,
      profit,
      loss,
      action,
      trades,
      wins,
      balances,
      adjusted,
      averageWin,
      averageLoss,
    } = snapshot.data();
    return {
      symbol,
      strategy,
      profit,
      loss,
      action,
      trades,
      wins,
      balances,
      adjusted,
      averageWin,
      averageLoss,
    };
  },
};

export const UserConverter: FirestoreDataConverter<User> = {
  toFirestore(user: User): DocumentData {
    return { ...user };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): User {
    const { firstName, lastName, email } = snapshot.data();
    return {
      firstName,
      lastName,
      email,
    };
  },
};
