import React from "react";

const routes = [
  {
    path: "/income",
    key: "income",
    component: React.lazy(() => import("pages/Income")),
  },
  {
    path: "/strategy",
    key: "strategy",
    component: React.lazy(() => import("pages/Strategies")),
  },
  {
    path: "/strategy/:strategyId",
    key: "strategy-detail",
    component: React.lazy(() => import("pages/StrategyDetails")),
  },
  {
    path: "/transactions",
    key: "transactions",
    component: React.lazy(() => import("pages/Transactions")),
  },
  {
    path: "/positions",
    key: "positions",
    component: React.lazy(() => import("pages/Positions")),
  },
  {
    path: "/trade-input",
    key: "trade-input",
    component: React.lazy(() => import("pages/Trade-Input")),
  },
  {
    path: "/transactions/:transactionId",
    key: "transaction-detail",
    component: React.lazy(() => import("pages/TransactionDetails")),
  },
  {
    path: "/positions/:positionId",
    key: "position-detail",
    component: React.lazy(() => import("pages/PositionDetails")),
  },
];

export default routes;
