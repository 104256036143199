import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import useFirebase from "hooks/useFirebase";
import React from "react";
import usePageLoading from "hooks/usePageLoading";

const Login: React.FC<{
  open: boolean;
  close?: boolean;
  onClose?: () => void;
}> = ({ open, close = false, onClose = () => ({}) }) => {
  const { app } = useFirebase();
  const { isLoading, setText } = usePageLoading();
  return (
    <dialog
      className={classNames(
        "modal",
        open && "modal-open",
        "modal-bottom",
        "sm:modal-middle"
      )}
    >
      <div className="modal-box flex flex-col items-center gap-2">
        {close && (
          <button
            onClick={onClose}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
        )}
        <div className="p-6 rounded-full border border-primary">
          <img className="h-16" alt="Login Logo" src="/logo192.png" />
        </div>
        <span className="font-bold text-2xl">Sign In</span>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={async (values, { setErrors }) => {
            const { username, password } = values;
            if (!username || !password) {
              setErrors({
                username: " ",
                password: "Username and password required",
              });
              return;
            }
            try {
              await signInWithEmailAndPassword(
                getAuth(app),
                username,
                password
              );
              if (isLoading) {
                setText("Fetching Accounts");
              }
            } catch (e) {
              setErrors({
                username: " ",
                password: "Username and password don't match",
              });
            }
          }}
        >
          {({ errors }) => (
            <Form className="w-full flex flex-col items-center mt-3 gap-4">
              <Field
                type="text"
                className={classNames(
                  "input input-primary w-80",
                  errors.username && "border-red-600"
                )}
                placeholder="username"
                name="username"
              />
              <div className="flex flex-col">
                <Field
                  type="password"
                  placeholder="password"
                  className={classNames(
                    "input input-primary w-80",
                    errors.password && "border-red-600"
                  )}
                  name="password"
                />
                {errors.password && (
                  <span className="text-red-600 text-sm">
                    {errors.password}
                  </span>
                )}
              </div>
              <div className="modal-action">
                <button
                  type="submit"
                  className="btn btn-primary !text-white w-40 transition-all hover:scale-105"
                >
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </dialog>
  );
};

export default Login;
