import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import useFirebase from "./useFirebase";
import {
  useCollectionOnce,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  getFirestore,
  collection,
  doc,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";
import { Account } from "types";
import { AccountConverter } from "utils/converters";
import { AccountCreate } from "components/AccountCreate";
import usePageLoading from "./usePageLoading";

type AccountInfo = {
  accounts?: Account[];
  selectedAccount?: Account;
};

type AccountContext = {
  accountInfo?: AccountInfo;
  setAccountInfo?: (accountInfo: AccountInfo) => void;
};

const context = createContext<AccountContext>({});
const getAccounts = (snapshot?: QuerySnapshot<Account, DocumentData>) => {
  return snapshot
    ? snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    : [];
};
export const Accounts = ({ children }: { children: ReactNode }) => {
  const { app } = useFirebase();
  const { setIsLoading } = usePageLoading();

  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const [user] = useAuthState(auth);

  let selectedAccount: Account | undefined = undefined;

  const accountsRef = collection(
    firestore,
    `analyzer/${user?.uid}/accounts`
  ).withConverter(AccountConverter);

  const [accountsSnapshot, accountsLoading] = useCollectionOnce(accountsRef);
  const [selectedSnapshot, selectedLoading] = useDocumentData(
    doc(firestore, `analyzer/${user?.uid}/selectedAccounts/selectedAccount`)
  );

  const isLoading = accountsLoading || selectedLoading;

  const pulledAccounts: Account[] = useMemo(
    () => getAccounts(accountsSnapshot),
    [accountsSnapshot]
  );

  if (selectedSnapshot) {
    selectedAccount = selectedSnapshot as Account;
  }

  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    accounts: pulledAccounts,
    selectedAccount,
  });

  useEffect(() => {
    if (
      !accountsLoading &&
      !accountInfo.accounts?.length &&
      pulledAccounts.length
    ) {
      if (!selectedLoading) {
        setAccountInfo({
          ...accountInfo,
          accounts: pulledAccounts,
          selectedAccount,
        });
        setIsLoading(false);
      }
    }
  }, [
    accountInfo,
    accountInfo.accounts,
    pulledAccounts,
    accountsLoading,
    selectedLoading,
    selectedAccount,
    setIsLoading,
  ]);

  const accountContext = {
    accountInfo,
    setAccountInfo,
  };

  const { accounts = [] } = accountInfo;
  return (
    <context.Provider value={accountContext}>
      {accounts.length && !isLoading ? (
        children
      ) : (
        <AccountCreate
          open={accounts.length === 0 && !isLoading}
          onClose={() => setIsLoading(false)}
        />
      )}
    </context.Provider>
  );
};

export const useAccounts = () => {
  const { accountInfo } = useContext(context);
  const { accounts } = accountInfo!;
  return { accounts };
};

export const useAccountInfo = () => {
  const { accountInfo, setAccountInfo } = useContext(context);
  return { accountInfo: accountInfo!, setAccountInfo: setAccountInfo! };
};
